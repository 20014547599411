import { faCircleInfo, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Group,
  Paper,
  Skeleton,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { MonthPicker } from "@mantine/dates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";

import api from "../../../api";
import { MonthlyComparisonChartResponse } from "../../../apiQueries/monthlyComparison";
import ErrorScreen from "../../../components/ErrorScreen";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import ScreenLoader from "../../../components/uikit/ScreenLoader";
import { useCurrentOrgExternalCodeWithFallback } from "../../../state/useOrg";
import { CostAreaChart } from "./components/CostAreaChart";
import { AVAILABLE_COLUMNS } from "./constants";
import type {
  MonthlySummaryPagePresentationProps,
  ReportOptions,
} from "./types";

const ReportControls = ({
  reportOptions,
  onOptionsChange,
}: {
  reportOptions: ReportOptions;
  onOptionsChange: (options: ReportOptions) => void;
}): React.JSX.Element => (
  <Stack gap="md">
    <Group align="center">
      <Switch
        label="Include linked accounts"
        checked={reportOptions.includeLinkedAccounts}
        onChange={(event) =>
          onOptionsChange({
            ...reportOptions,
            includeLinkedAccounts: event.currentTarget.checked,
          })
        }
      />
      <Tooltip label="Include data from all linked AWS accounts in addition to the payer account. This affects the CSV export only.">
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{ color: "var(--mantine-color-gray-6)" }}
        />
      </Tooltip>
    </Group>
    {/* Hidden until private pricing calculations are ready
    <Group align="center">
      <Switch
        label="Private pricing breakout"
        checked={reportOptions.includePrivatePricing}
        onChange={(event) =>
          onOptionsChange({
            ...reportOptions,
            includePrivatePricing: event.currentTarget.checked,
          })
        }
      />
      <Tooltip label="Show detailed pricing information including private pricing agreements and discounts in the CSV export. This data is not shown in the graph view.">
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{ color: "var(--mantine-color-gray-6)" }}
        />
      </Tooltip>
    </Group>
    */}
  </Stack>
);

const constructDownloadUrl = (
  startDate: Date,
  endDate: Date,
  options: ReportOptions,
  orgExternalCode: string,
) => {
  return `${api.axios.getUri() ?? ""}/api/v1/organizations/${orgExternalCode}/reconciliation/monthly_comparison/csv?${new URLSearchParams(
    {
      aggregation: options.includeLinkedAccounts
        ? options.includePrivatePricing
          ? "linked-private"
          : "linked"
        : options.includePrivatePricing
          ? "payer-private"
          : "payer",
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
    },
  )}`;
};

export function MonthlySummaryPagePresentation({
  availableMonths,
  dateRange,
  setDateRange,
  reportOptions,
  setReportOptions,
  orgExternalCode,
  chartData,
  isChartLoading,
  effectiveDateRange,
}: MonthlySummaryPagePresentationProps): React.JSX.Element {
  const availableDates = availableMonths.map((dateStr) => {
    const [year, month] = dateStr.split("-").map(Number);
    return new Date(year, month - 1);
  });

  const minDate = availableDates[0];
  const maxDate = availableDates[availableDates.length - 1];

  const [startDate, endDate] = dateRange;
  const isDateRangeValid = startDate && endDate;

  const downloadUrl =
    isDateRangeValid && effectiveDateRange
      ? constructDownloadUrl(
          effectiveDateRange[0],
          effectiveDateRange[1],
          reportOptions,
          orgExternalCode,
        )
      : "";

  return (
    <LayoutV2>
      <Box className="reconciliation-page-layout">
        <Box p="lg">
          <Title>Monthly Summary</Title>
          <Text c="dimmed" mt="sm" mb="lg">
            Please select the month range and a service to get focused analytic
            report.
          </Text>

          <Paper p="md" bg="gray.0">
            <Paper p="xl" shadow="sm">
              <Stack gap="xl">
                <Box>
                  <Text size="sm" fw={500} mb="xs">
                    Month Range
                  </Text>
                  <MonthPicker
                    type="range"
                    value={dateRange}
                    onChange={setDateRange}
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultDate={maxDate}
                  />
                </Box>

                <ReportControls
                  reportOptions={reportOptions}
                  onOptionsChange={setReportOptions}
                />

                {isChartLoading ? (
                  <Skeleton height={300} radius="md" />
                ) : (
                  isDateRangeValid &&
                  chartData &&
                  chartData.length > 0 && (
                    <CostAreaChart
                      data={chartData}
                      selectedColumns={reportOptions.selectedColumns}
                      onColumnsChange={(columns) =>
                        setReportOptions({
                          ...reportOptions,
                          selectedColumns: columns,
                        })
                      }
                    />
                  )
                )}

                <Box>
                  {isDateRangeValid ? (
                    <Button
                      component="a"
                      href={downloadUrl}
                      download="monthly_summary.csv"
                      size="md"
                      variant="filled"
                      color="blue"
                      leftSection={<FontAwesomeIcon icon={faDownload} />}
                    >
                      Download Report
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      variant="filled"
                      color="blue"
                      disabled
                      leftSection={<FontAwesomeIcon icon={faDownload} />}
                    >
                      Download Report
                    </Button>
                  )}
                </Box>
              </Stack>
            </Paper>
          </Paper>
        </Box>
      </Box>
    </LayoutV2>
  );
}

export default function MonthlySummaryPage(): React.JSX.Element {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    includeLinkedAccounts: false,
    includePrivatePricing: false,
    selectedColumns: AVAILABLE_COLUMNS.slice(0, 5), // Default to first 5 columns
  });
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();

  // Calculate effective date range (excluding current month) once
  const effectiveDateRange = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) return null;

    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
    );

    return [
      dateRange[0],
      dateRange[1] < firstDayOfCurrentMonth
        ? dateRange[1]
        : new Date(firstDayOfCurrentMonth.getTime() - 86400000),
    ] as [Date, Date];
  }, [dateRange]);

  const { data: availableMonths, error: availableMonthsError } = useQuery({
    queryKey: ["reconciliation_available_months"],
    queryFn: () =>
      api.getReconciliationAvailableMonths({
        orgExternalCode: orgExternalCode ?? "",
      }),
    enabled: !!orgExternalCode,
  });

  // Filter available months to exclude current month
  const filteredAvailableMonths = useMemo(() => {
    if (!availableMonths) return null;

    const currentDate = new Date();
    const currentYearMonth = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1,
    ).padStart(2, "0")}`;

    return {
      ...availableMonths,
      availableMonths: availableMonths.availableMonths.filter(
        (month) => month < currentYearMonth,
      ),
    };
  }, [availableMonths]);

  const {
    data: chartData,
    error: chartError,
    isLoading: isChartLoading,
  } = useQuery<MonthlyComparisonChartResponse>({
    placeholderData: keepPreviousData,
    queryKey: [
      "monthly_comparison_chart_data",
      orgExternalCode,
      effectiveDateRange,
    ],
    queryFn: async () => {
      if (!effectiveDateRange || !orgExternalCode) {
        throw new Error("Invalid parameters");
      }

      return api.getMonthlyComparisonChartData({
        orgExternalCode: orgExternalCode,
        startDate: effectiveDateRange[0],
        endDate: effectiveDateRange[1],
        includeLinkedAccounts: false,
        includePrivatePricing: false,
      });
    },
    enabled: !!orgExternalCode && !!effectiveDateRange,
  });

  if (availableMonthsError || chartError) {
    return <ErrorScreen />;
  }

  if (!filteredAvailableMonths || !orgExternalCode) {
    return <ScreenLoader />;
  }

  return (
    <MonthlySummaryPagePresentation
      availableMonths={filteredAvailableMonths.availableMonths}
      dateRange={dateRange}
      setDateRange={setDateRange}
      reportOptions={reportOptions}
      setReportOptions={setReportOptions}
      orgExternalCode={orgExternalCode}
      chartData={chartData?.data}
      isChartLoading={isChartLoading}
      effectiveDateRange={effectiveDateRange}
    />
  );
}
