import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import Plot from "react-plotly.js";
import api from "../../api.ts";
import ErrorComponent from "../../components/ErrorComponent.jsx";
import ErrorScreen from "../../components/ErrorScreen";
import { faArrowsRotate, faContract } from "../../components/icons.jsx";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import Money from "../../components/Money.jsx";
import Button from "../../components/uikit/Button";
import Card, { CardBody } from "../../components/uikit/Card";
import BulletBarChart from "../../components/uikit/charts/BulletBarChart";
import GanttChart from "../../components/uikit/charts/GanttChart.jsx";
import ComponentLoader from "../../components/uikit/ComponentLoader.jsx";
import PageHeading from "../../components/uikit/PageHeading.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import dayjs from "../../modules/dayConfig";
import spluralize from "../../modules/spluralize.ts";
import useAsyncFetch from "../../state/useAsyncFetch.jsx";
import {
  orgPath,
  useCurrentOrgExternalCodeWithFallback,
} from "../../state/useOrg";

export default function ContractManagerPage() {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const {
    loading,
    error,
    fetched,
    fetchedState: state,
  } = useAsyncFetch(api.getContractDashboard, {
    fetchArgs: { orgExternalCode },
  });

  return (
    <LayoutV2>
      {loading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {fetched && (
        <>
          <Stack className="page-content">
            <Stack gap={4}>
              <PageHeading>Contracts Dashboard</PageHeading>
              <Stack row className="w-100" gap={4}>
                <Card className="flex-1">
                  <CardBody>
                    <TotalSpendChart />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <PeriodSpendChart />
                  </CardBody>
                </Card>
              </Stack>
              <Stack>
                <Card>
                  <CardBody>
                    <EffectiveDiscountRateChart />
                  </CardBody>
                </Card>
              </Stack>
              <Card>
                <CardBody>
                  <Stack>
                    <Stack row className="justify-between mb-3">
                      <Stack row className="align-center">
                        <FontAwesomeIcon
                          icon={faContract}
                          className="rounded color-background bg-blurple"
                          style={{
                            fontSize: 16,
                            width: 18,
                            height: 18,
                            padding: 12,
                          }}
                        />
                        <h3 className="ml-3">
                          {state.activeContracts} Active{" "}
                          {spluralize(1, "Contract")}
                        </h3>
                      </Stack>
                      <Button
                        href={orgPath("/contracts/list", orgExternalCode)}
                        rightArrow
                      >
                        View Contracts
                      </Button>
                    </Stack>
                    <GanttChart
                      today={dayjs.utc(state.today)}
                      start={dayjs.utc(state.today).startOf("year")}
                      monthsForward={state.contractGantt.monthsForward}
                      items={state.contractGantt.items.map(
                        ({ start, end, markers, href, ...rest }) => ({
                          ...rest,
                          icon: faContract,
                          start: dayjs.utc(start),
                          end: dayjs.utc(end),
                          href,
                          markers: markers.map(({ at, ...rest }) => ({
                            at: dayjs(at),
                            icon: faArrowsRotate,
                            ...rest,
                          })),
                        }),
                      )}
                    />
                  </Stack>
                </CardBody>
              </Card>
            </Stack>
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

function TotalSpendChart() {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const { loading, error, fetched, fetchedState } = useAsyncFetch(
    api.getContractDashboardOrgwideSpendHistoryChart,
    { fetchArgs: { orgExternalCode } },
  );
  return (
    <Stack>
      <h3 className="mb-4">Total Spend ($)</h3>
      {loading && <ComponentLoader height={200} />}
      {error && <ErrorComponent style={{ height: 200 }} />}
      {fetched && (
        <BulletBarChart
          height={200}
          width="100%"
          barWidth={20}
          yAxis={fetchedState.yAxis}
          items={fetchedState.items.map((item) => ({ ...item }))}
        />
      )}
    </Stack>
  );
}

const EffectiveDiscountRateChart = () => {
  const startDate = dayjs().subtract(1, "year");
  const endDate = dayjs();
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();

  const { loading, error, fetched, fetchedState } = useAsyncFetch(
    api.getContractDashboardEffectiveDiscountRateChart,
    {
      fetchArgs: {
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
        orgExternalCode,
      },
    },
  );
  const maxEdr = fetchedState?.reduce?.((max, monthResult) => {
    return Math.max(
      max,
      monthResult.effectiveDiscountRate,
      monthResult.effectiveDiscountRateFromCredits,
    );
  }, 0);
  return (
    <>
      <h3 className="mb-4">Effective Discount Rate</h3>
      {loading && <ComponentLoader height={200} />}
      {error && <ErrorComponent style={{ height: 200 }} />}
      {fetched && (
        <Plot
          config={{ displayModeBar: false }}
          layout={{
            height: 200,
            yaxis: {
              range: [0, maxEdr * 100 + 5],
              fixedrange: true,
            },
            xaxis: {
              fixedrange: true,
              dtick: "M1",
            },
            margin: { t: 0, r: 30, l: 30, b: 50, pad: 8 },
          }}
          data={[
            {
              name: "Effective Discount Rate",
              line: { width: 5 },
              x: fetchedState.map((monthResult) => monthResult.monthStart),
              y: fetchedState.map(
                (monthResult) => monthResult.effectiveDiscountRate * 100,
              ),
              hovertemplate: "%{y:.1f}%<extra></extra>",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: "#3AC4FF", size: 10 },
              stackgroup: "one",
            },
          ]}
        />
      )}
    </>
  );
};

function PeriodSpendChart() {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const { loading, error, fetched, fetchedState } = useAsyncFetch(
    api.getContractDashboardPeriodSpendChart,
    { fetchArgs: { orgExternalCode } },
  );
  return (
    <Stack className="align-center" gap={2}>
      <p className="mb-3">Spend This Year</p>
      {loading && <ComponentLoader height={200} />}
      {error && <ErrorComponent />}
      {fetched && (
        <Stack className="align-center" gap={2}>
          <BulletBarChart
            height={200}
            width="100%"
            barWidth={48}
            yAxis={fetchedState.chart.yAxis}
            items={fetchedState.chart.items.map((item) => ({ ...item }))}
          />
          <h3>
            <Money>{fetchedState.actual}</Money>
          </h3>
          <PeriodSpendCommitment {...fetchedState} />
        </Stack>
      )}
    </Stack>
  );
}

function PeriodSpendCommitment({ over, under, onTarget, target }) {
  let color;
  if (onTarget) {
    color = "foreground";
  } else if (over) {
    color = "orange";
  } else if (under) {
    color = "red";
  }
  return (
    <p className={clsx("subtitle", `color-${color}`)}>
      Goal: <Money>{target}</Money>
    </p>
  );
}
