import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { AnimatePresence } from "framer-motion";
import React from "react";
import api from "../../api";
import ErrorScreen from "../../components/ErrorScreen";
import {
  faAt,
  faPaperPlaneSolid,
  faPlusSolid,
  faTrashSolid,
  faXmark,
} from "../../components/icons.jsx";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import ShrinkingDiv from "../../components/uikit/animate/ShrinkingDiv.jsx";
import Button from "../../components/uikit/Button";
import Card, { CardBody } from "../../components/uikit/Card";
import Form from "../../components/uikit/Form.jsx";
import Hr from "../../components/uikit/Hr";
import InlineSelectField from "../../components/uikit/InlineSelectField.jsx";
import ConfirmationModal from "../../components/uikit/modals/ConfirmationModal.jsx";
import PageHeading from "../../components/uikit/PageHeading.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import SelectField from "../../components/uikit/SelectField.jsx";
import Stack from "../../components/uikit/Stack";
import TextField from "../../components/uikit/TextField.jsx";
import useErrorToast from "../../state/useErrorToast.jsx";
import useFormSubmitter from "../../state/useFormSubmitter.js";
import { useCurrentOrgExternalCodeWithFallback } from "../../state/useOrg";
import useRoleAccess from "../../state/useRoleAccess.tsx";
import useScreenLoader from "../../state/useScreenLoader";
import useToast from "../../state/useToast.jsx";
import useToggle from "../../state/useToggle";
import useUser from "../../state/useUser";

export default function ManageOrgMembersPage() {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const memberQueryKey = ["org", orgExternalCode, "members"];
  const queryClient = useQueryClient();
  const {
    data: membersResponse,
    isLoading: membersLoading,
    isError: membersError,
    isSuccess: membersFetched,
  } = useQuery({
    queryFn: () =>
      api.getOrganizationMembers({ orgExternalCode: orgExternalCode }),
    queryKey: memberQueryKey,
  });
  const members = membersResponse?.items;
  const refetchMembers = () =>
    queryClient.invalidateQueries({ queryKey: memberQueryKey });

  const inviteModalToggle = useToggle();

  return (
    <LayoutV2>
      {membersLoading && <ScreenLoader />}
      {membersError && <ErrorScreen />}
      {membersFetched && (
        <>
          <InviteUserModal
            toggle={inviteModalToggle}
            onMembershipsChanged={refetchMembers}
          />
          <Stack className="page-content">
            <Stack col>
              <Stack row className="justify-between">
                <PageHeading>Team Members</PageHeading>
                <Button
                  rightArrow={faPaperPlaneSolid}
                  onClick={inviteModalToggle.turnOn}
                >
                  Invite a Team Member
                </Button>
              </Stack>
              <Hr my={5} />
            </Stack>
            <Stack row gap={5} className="flex-wrap">
              <AnimatePresence>
                {members?.map((member, i) => (
                  <ShrinkingDiv
                    key={`${i}${member.external_code}`}
                    style={{ scaleY: 0, opacity: 0 }}
                    animate={{
                      scaleY: "100%",
                      opacity: 1,
                    }}
                  >
                    <Member {...member} onMembershipsChanged={refetchMembers} />
                  </ShrinkingDiv>
                ))}
              </AnimatePresence>
              <InviteMember modalToggle={inviteModalToggle} />
            </Stack>
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

function Member({
  email,
  invitedAt,
  name,
  role,
  userId,
  avatarSrc,
  organizationName,
  onMembershipsChanged,
}) {
  const screenLoader = useScreenLoader();
  const { showErrorToast } = useErrorToast();
  const { canWrite } = useRoleAccess();
  const { showToast } = useToast();
  const { user } = useUser();
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();

  const removeToggle = useToggle();

  function handleChangeRole(value) {
    screenLoader.turnOn();
    api
      .changeOrganizationMemberRole({ userId, role: value, orgExternalCode })
      .then((r) => onMembershipsChanged(r.data))
      .catch(showErrorToast)
      .finally(screenLoader.turnOff);
  }

  function handleResendInvite() {
    showToast({ title: "Sent a new invitation.", variant: "success" });
    api
      .reinviteOrganizationMember({ userId, orgExternalCode })
      .catch(showErrorToast);
  }

  function handleRemove() {
    screenLoader.turnOn();
    api
      .removeOrganizationMember({ userId, orgExternalCode })
      .then((r) => {
        removeToggle.turnOff();
        return onMembershipsChanged(r.data);
      })
      .catch(showErrorToast)
      .finally(screenLoader.turnOff);
  }

  let roleStackEl = null;
  if (userId === user.id) {
    roleStackEl = null;
  } else if (invitedAt) {
    roleStackEl = (
      <Stack row className="justify-between align-center">
        <p className="text-desc text-strong">
          Invited {dayjs(invitedAt).format("L")}
        </p>
        {canWrite("orgmembers") && (
          <Stack row gap={2}>
            <Button variant="danger" size="sm" onClick={removeToggle.turnOn}>
              <FontAwesomeIcon icon={faXmark} />
            </Button>
            <Button
              variant="info"
              rightArrow={faPaperPlaneSolid}
              size="sm"
              onClick={handleResendInvite}
            >
              Resend
            </Button>
          </Stack>
        )}
      </Stack>
    );
  } else if (canWrite("orgmembers")) {
    roleStackEl = (
      <Stack row className="justify-between align-center">
        <InlineSelectField
          label="User Role"
          value={role.key}
          items={roleSelectChoices}
          onChange={handleChangeRole}
        />
        <Stack row gap={2}>
          <Button variant="danger" size="sm" onClick={removeToggle.turnOn}>
            <FontAwesomeIcon icon={faTrashSolid} />
          </Button>
        </Stack>
      </Stack>
    );
  }
  return (
    <Card style={{ width: 330 }} variant={invitedAt && "grey-on-grey"}>
      <CardBody>
        <Stack col gap={3}>
          <Stack row>
            <img
              className="border-radius-50 mr-2"
              src={avatarSrc}
              height={40}
              width={40}
              alt="profile picture"
            />
            <Stack col>
              <h6 className="color-blurple mb-2">{name}</h6>
              <p className="color-grey">{role.name}</p>
            </Stack>
          </Stack>

          <Stack col>
            <p className="flex row align-center">
              <FontAwesomeIcon icon={faAt} className="mr-1 subtitle" />{" "}
              <a className="text-desc" href={`mailto:${email}`}>
                {email}
              </a>
            </p>
          </Stack>
          {roleStackEl}
        </Stack>
      </CardBody>
      <ConfirmationModal
        heading="Remove Team Member"
        isOpen={removeToggle.isOn}
        onOpenChange={removeToggle.setState}
        reject
        confirm="Remove"
        onReject={removeToggle.turnOff}
        onConfirm={handleRemove}
      >
        <p className="mb-4">
          Are you sure you want to remove <strong>{name}</strong> from the{" "}
          <strong>{organizationName}</strong> team?
        </p>
        <p className="color-red">
          <strong>Warning: This action cannot be undone.</strong>
        </p>
      </ConfirmationModal>
    </Card>
  );
}

function InviteMember({ modalToggle }) {
  const { cannotWrite } = useRoleAccess();

  if (cannotWrite("orgmembers")) {
    return null;
  }

  return (
    <Card variant="dashed" style={{ minHeight: 150, width: 330 }}>
      <Stack className="justify-center align-center h-100">
        <Button variant="light" size="huge" onClick={modalToggle.turnOn}>
          <FontAwesomeIcon icon={faPlusSolid} />
        </Button>
      </Stack>
    </Card>
  );
}

function InviteUserModal({ toggle, onMembershipsChanged }) {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [roleKey, setRoleKey] = React.useState("member");
  const { prepareSubmit, showErrorToast, turnOffScreenLoader } =
    useFormSubmitter();
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();

  function handleConfirm() {
    prepareSubmit();
    api
      .inviteOrganizationMember({ name, email, role: roleKey, orgExternalCode })
      .then((r) => {
        onMembershipsChanged(r.data);
        resetState();
        toggle.turnOff();
      })
      .catch(showErrorToast)
      .finally(turnOffScreenLoader);
  }

  function handleReject() {
    resetState();
    toggle.turnOff();
  }

  function resetState() {
    setEmail("");
    setName("");
    setRoleKey("member");
  }

  return (
    <ConfirmationModal
      heading="Invite a New Team Member"
      isOpen={toggle.isOn}
      onOpenChange={toggle.setState}
      reject
      confirm="Send Invite"
      confirmIcon={faPaperPlaneSolid}
      onConfirm={handleConfirm}
      onReject={handleReject}
    >
      <Form onSubmit={handleConfirm}>
        <TextField
          label="Name"
          type="name"
          placeholder="Name"
          wide
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Email address"
          type="email"
          placeholder="Enter email"
          marginTop
          wide
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <SelectField
          label="User Role"
          variant="white"
          marginTop
          wide
          value={roleKey}
          items={roleSelectChoices}
          required
          onChange={(k) => setRoleKey(k)}
        />
      </Form>
    </ConfirmationModal>
  );
}

const roleSelectChoices = [
  { value: "member", label: "Member" },
  { value: "admin", label: "Admin" },
];
