import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Center,
  Divider,
  Group,
  Paper,
  Stack,
  Tabs,
  Title,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useState } from "react";
import api from "../../../api.js";
import { DiscountDiscrepancyHierarchy } from "../../../apiQueries/reconciliation.js";
import DiscountDiscrepancyList from "../../../components/DiscountDiscrepancyList/DiscountDiscrepancyList.js";
import ErrorScreen from "../../../components/ErrorScreen.js";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import MonthSelector from "../../../components/MonthSelector/MonthSelector.js";
import { NoDataPage } from "../../../components/NoDataPage/NoDataPage.js";
import ComponentLoader from "../../../components/uikit/ComponentLoader.jsx";
import ScreenLoader from "../../../components/uikit/ScreenLoader.js";
import { useCurrentOrgExternalCodeWithFallback } from "../../../state/useOrg.js";
import "./ReconciliationPage.css";

export type ReconciliationPageLoadedProps = {
  discountDiscrepanciesHierarchy: DiscountDiscrepancyHierarchy;
};
export function ReconciliationPageLoaded({
  discountDiscrepanciesHierarchy,
}: ReconciliationPageLoadedProps): React.JSX.Element {
  return (
    <DiscountDiscrepancyList
      discountDiscrepanciesHierarchy={discountDiscrepanciesHierarchy}
    />
  );
}

export type ReconciliationPagePresentationProps = {
  discountDiscrepanciesHierarchy?: DiscountDiscrepancyHierarchy;
  availableMonths: string[];
  setMonth: (month: string) => void;
  selectedMonth: string | undefined;
  orgExternalCode: string;
};
export function ReconciliationPagePresentation({
  discountDiscrepanciesHierarchy,
  availableMonths,
  setMonth,
  selectedMonth,
  orgExternalCode,
}: ReconciliationPagePresentationProps): React.JSX.Element {
  // Set up the months to be displayed in the dropdown
  const latestMonth = availableMonths[availableMonths.length - 1];
  const fullyLoaded = !!discountDiscrepanciesHierarchy;
  const currentMonth = selectedMonth ?? latestMonth;
  return (
    <LayoutV2>
      <div className="reconciliation-page">
        <Box p="lg">
          <Title>Discount Discrepancies</Title>
          <Box pt="sm" pb="lg">
            Compare the expected discount for a month to the actual discount you
            received.
          </Box>
          <Box>
            <Tabs variant="pills" defaultValue="unreconciled">
              <Stack>
                <Paper p="md" bg="gray.0">
                  <Paper p="xl" shadow="sm">
                    <Tabs.Panel value="unreconciled">
                      <Group justify="space-between">
                        <Title order={2}>
                          <MonthSelector
                            availableMonths={availableMonths}
                            setMonth={setMonth}
                            selectedMonth={selectedMonth}
                          />
                        </Title>
                        <Button
                          component="a"
                          href={
                            (api.axios.getUri() ?? "") +
                            `/api/v1/organizations/${orgExternalCode}/reconciliation/discount_discrepancies/csv?month_start_date=${currentMonth}`
                          }
                        >
                          <FontAwesomeIcon icon={faDownload} />
                          <Box mx="sm">Export as CSV</Box>
                        </Button>
                      </Group>
                      <Divider my="sm" />
                      <Title order={3}>
                        <Group justify="space-between" my="sm">
                          <div>Service</div>
                          <div>
                            Cost
                            <span className="after-discount-text">
                              (After Discount)
                            </span>
                          </div>
                        </Group>
                      </Title>
                      {fullyLoaded ? (
                        <ReconciliationPageLoaded
                          discountDiscrepanciesHierarchy={
                            discountDiscrepanciesHierarchy
                          }
                        />
                      ) : (
                        <Center mih={300}>
                          <ComponentLoader />
                        </Center>
                      )}
                    </Tabs.Panel>
                  </Paper>
                </Paper>
              </Stack>
            </Tabs>
          </Box>
        </Box>
      </div>
    </LayoutV2>
  );
}

export default function ReconciliationPage(): React.JSX.Element {
  const [month, setMonth] = useState<string | undefined>();
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const { data: discountDiscrepanciesHierarchy, error } = useQuery({
    queryKey: ["discount_discrepancies", month],
    queryFn: () =>
      api.getReconciliationDiscountDiscrepancies({
        monthStartDate: dayjs(month),
        orgExternalCode: orgExternalCode ?? "",
      }),
    enabled: !!orgExternalCode,
    retry: false,
  });

  const { data: availableMonths, error: availableMonthsError } = useQuery({
    queryKey: ["reconciliation_available_months"],
    queryFn: () =>
      api.getReconciliationAvailableMonths({
        orgExternalCode: orgExternalCode ?? "",
      }),
    enabled: !!orgExternalCode,
  });

  if (error || availableMonthsError) {
    return <ErrorScreen />;
  }
  if (!availableMonths || !orgExternalCode) {
    return <ScreenLoader />;
  }

  if (availableMonths.availableMonths.length === 0) {
    return <NoDataPage />;
  }
  return (
    <ReconciliationPagePresentation
      discountDiscrepanciesHierarchy={discountDiscrepanciesHierarchy}
      availableMonths={availableMonths.availableMonths}
      setMonth={setMonth}
      selectedMonth={month}
      orgExternalCode={orgExternalCode}
    />
  );
}
