import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import ErrorScreen from "../components/ErrorScreen";
import LayoutV2 from "../components/LayoutV2/LayoutV2";
import Button from "../components/uikit/Button";
import Card from "../components/uikit/Card";
import Hr from "../components/uikit/Hr";
import PageHeading from "../components/uikit/PageHeading";
import ScreenLoader from "../components/uikit/ScreenLoader";
import Stack from "../components/uikit/Stack";
import { useCurrentOrg, useOrgExternalCodeFromRoute } from "../state/useOrg";
import useUser from "../state/useUser";

export default function DashboardPage() {
  // We want to handle two versions of this page:
  // 1. /org/123/dashboard where we show the version of the dashboard appropriate for this org
  // 2. /dashboard where we show non-org-specific content (eg the option to accept a membership to an org).
  // If you're ever in version 2 and we detect that you're a member of an org, we'll redirect you to version 1.
  const orgExternalCodeFromRoute = useOrgExternalCodeFromRoute();
  const { data: currentOrg } = useCurrentOrg(true);

  const { sampleFeature } = useFlags();
  console.log("DashboardPage, sampleFeature:", sampleFeature);

  const { user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    // If we're on the default /dashboard page...
    if (!orgExternalCodeFromRoute)
      if (
        user?.supervisingOrganizations &&
        user.supervisingOrganizations.length > 0
      ) {
        // If you supervise other orgs (eg you're a PE firm with portfolio
        // companies), redirect to the list of orgs you supervise
        navigate("/supervised-organizations");
      } else if (currentOrg) {
        // Redirect to the first available org (or the last org you were a member of)
        navigate(`/org/${currentOrg.externalCode}/dashboard`);
      }
  }, [orgExternalCodeFromRoute, currentOrg, navigate, user]);

  const { data, isLoading, error, isSuccess } = useQuery({
    queryFn: () =>
      api.getDashboard({ orgExternalCode: orgExternalCodeFromRoute }),
    queryKey: ["org", orgExternalCodeFromRoute, "dashboard"],
  });
  return (
    <LayoutV2>
      {isLoading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {isSuccess && (
        <Stack col gap={5} className="p-5" style={{ maxWidth: 1200 }}>
          <PageHeading>Welcome to Skyway</PageHeading>
          <Hr />
          <Stack row gap={5} className="flex-wrap">
            {data.issues.map(({ key, message, cta, url }: any, i: number) => (
              <IssueCard
                key={`${key}${i}`}
                message={message}
                cta={cta}
                url={url}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </LayoutV2>
  );
}

function IssueCard({ message, cta, url }: any) {
  return (
    <Card style={{ maxWidth: 364 }} className="p-5">
      <Stack col className="justify-between h-100">
        <p className="mb-4">{message}</p>
        <Button href={url} rightArrow variant="hollow">
          {cta}
        </Button>
      </Stack>
    </Card>
  );
}
