import dayjs from "dayjs";
import React from "react";
import api from "../../api";
import ErrorScreen from "../../components/ErrorScreen";
import Number from "../../components/Number.jsx";
import SafeExternalLink from "../../components/SafeExternalLink.jsx";
import Form from "../../components/uikit/Form.jsx";
import FormButtons from "../../components/uikit/FormButtons.jsx";
import Indicator from "../../components/uikit/Indicator.tsx";
import Markdown from "../../components/uikit/Markdown.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import TextField from "../../components/uikit/TextField.jsx";
import { useAsyncCollectionFetch } from "../../state/useAsyncFetch.jsx";
import { useCurrentOrgExternalCodeWithFallback } from "../../state/useOrg";
import useRoleAccess from "../../state/useRoleAccess.tsx";

import capitalize from "lodash/capitalize.js";
import { DialogTrigger } from "react-aria-components";
import {
  faCalendarCheck,
  faLinkSlashSolid,
  faTag,
} from "../../components/icons.jsx";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import Button from "../../components/uikit/Button";
import Hr from "../../components/uikit/Hr";
import InformationModal from "../../components/uikit/modals/InformationModal.jsx";
import PageHeading from "../../components/uikit/PageHeading.jsx";
import StatCard from "../../components/uikit/StatCard";
import StatCardRow from "../../components/uikit/StatCardRow.jsx";
import useFormSubmitter from "../../state/useFormSubmitter.js";

export default function AwsConnectionPage() {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const {
    loading,
    error,
    fetched,
    items,
    scratchItems,
    combinedItemAt,
    replaceItemAt,
    setScratchItemAt,
  } = useAsyncCollectionFetch(api.getAwsConnections, {
    fetchArgs: { orgExternalCode },
  });
  const {
    prepareSubmit,
    showErrorToast,
    showSuccessToast,
    turnOffScreenLoader,
  } = useFormSubmitter();
  const [verifyResponse, setVerifyResponse] = React.useState(null);

  function handleSubmit(e, idx) {
    prepareSubmit(e);
    api
      .updateAwsConnection({
        id: items[idx].id,
        ...scratchItems[idx],
        orgExternalCode,
      })
      .then((r) => {
        showSuccessToast("AWS Connection settings updated");
        replaceItemAt(idx, r.data);
      })
      .catch(showErrorToast)
      .finally(turnOffScreenLoader);
  }

  function handleVerify(e, idx) {
    prepareSubmit(e);
    api
      .verifyAwsConnection({ id: items[idx].id, orgExternalCode })
      .then((r) => {
        replaceItemAt(idx, r.data);
        setVerifyResponse(r.data);
      })
      .catch(showErrorToast)
      .finally(turnOffScreenLoader);
  }

  const conn = items[0];
  return (
    <LayoutV2>
      {loading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {fetched && (
        <>
          <Stack className="page-content">
            <Stack col gap={5}>
              <Stack row className="align-center">
                <PageHeading>CUR Ingestion</PageHeading>
                <div>
                  <Indicator name={conn.status} className="ml-3" />
                </div>
              </Stack>
              <p>
                Set up and check on Skyway&rsquo;s connection to the S3 bucket
                housing your CUR.
              </p>
              <Hr />
              <h5 className="color-blurple">Your CUR Ingestion Status</h5>
              <StatCardRow>
                <StatCard
                  title="Status"
                  subtitle={capitalize(conn.status)}
                  color="red"
                  icon={faLinkSlashSolid}
                />
                {conn.lastIngestedAt && (
                  <>
                    <StatCard
                      title="Last Sync"
                      subtitle={dayjs(conn.statusChangedAt).format("lll")}
                      color="grey"
                      icon={faTag}
                    />
                    <StatCard
                      title="Total Row Count"
                      subtitle={<Number>{conn.curRowCount}</Number>}
                      color="grey"
                      icon={faCalendarCheck}
                    />
                  </>
                )}
              </StatCardRow>
              <Hr />
              <h5 className="color-blurple">Your AWS Settings</h5>
              <UpdateConnection
                awsConnection={combinedItemAt(0)}
                onSubmit={(e) => handleSubmit(e, 0)}
                onFieldChange={(o) =>
                  setScratchItemAt(0, { ...scratchItems[0], ...o })
                }
              />
              {conn.canTryToVerify && (
                <>
                  <Hr />
                  <h5 className="color-blurple">Setup Instructions</h5>
                  <p>
                    To set up Skyway, you&rsquo;ll need to create an AWS Role in
                    your organization that Skyway can use to collect the
                    information it needs. This can be done with AWS CLI.
                  </p>
                  <p>
                    Once the configuration is set up, ask your AWS Account
                    Manager to have the Data Export named{" "}
                    <strong>skyway-export</strong> backfilled with the full
                    history they can (3 years).
                  </p>
                  <Instructions awsConnection={combinedItemAt(0)} />
                  <Hr />
                  <h5 className="color-blurple">Verify Connection</h5>
                  <VerifyConnection
                    verifyResponse={verifyResponse}
                    onVerify={(e) => handleVerify(e, 0)}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

function UpdateConnection({ awsConnection, onFieldChange, onSubmit }) {
  const { canWrite } = useRoleAccess();
  const disabled = !canWrite("curingest");
  const inputProps = {
    type: "text",
    style: { minWidth: 250 },
    onChange: (e) => onFieldChange({ [e.target.name]: e.target.value }),
  };
  return (
    <Form
      onSubmit={onSubmit}
      className="flex row flex-wrap gap-3"
      style={{ maxWidth: 1100, padding: "24px" }}
    >
      <TextField
        label="Root Payer Account ID"
        disabled={disabled}
        className="flex-1"
        infoModal={
          <span>
            Follow{" "}
            <SafeExternalLink href="https://docs.aws.amazon.com/accounts/latest/reference/manage-acct-identifiers.html">
              these instructions
            </SafeExternalLink>{" "}
            to find your Account ID.
          </span>
        }
        inputProps={{
          ...inputProps,
          name: "rootPayerAccountId",
          value: awsConnection.rootPayerAccountId,
        }}
      />
      <TextField
        label="CUR S3 Bucket"
        disabled={disabled}
        className="flex-1"
        infoModal={
          <span>
            This is the S3 bucket where you&rsquo;re currently writing CUR files
            in your account.
          </span>
        }
        inputProps={{
          ...inputProps,
          name: "curS3Bucket",
          value: awsConnection.curS3Bucket,
        }}
      />
      <TextField
        label="CUR S3 Prefix"
        disabled={disabled}
        className="flex-1"
        infoModal={
          <span>
            Common prefix for CUR files. This is often something like{" "}
            <code className="language-sh">athena/CUR-athena/CUR-athena</code>.
            The name of the folder is usually the Cost & Usage Report name.
          </span>
        }
        inputProps={{
          ...inputProps,
          name: "curS3Prefix",
          value: awsConnection.curS3Prefix,
        }}
      />
      <FormButtons
        submit="Save Changes"
        submitProps={{ disabled }}
        infoMessage={
          disabled && "These fields can only be updated by administrators."
        }
      />
    </Form>
  );
}

function Instructions({ awsConnection }) {
  return (
    <Stack row gap={3}>
      <InstructionButton
        awsConnection={awsConnection}
        label="AWS CLI"
        which="awscli"
      />
    </Stack>
  );
}

function InstructionButton({ awsConnection, label, which }) {
  return (
    <DialogTrigger>
      <Button>{label}</Button>
      <InformationModal closeButton style={{ maxWidth: "80vw" }}>
        <Markdown syntaxHighlight>
          {awsConnection.instructions[`${which}Install`]}
        </Markdown>
      </InformationModal>
    </DialogTrigger>
  );
}

function VerifyConnection({ verifyResponse, onVerify }) {
  const { cannotWrite } = useRoleAccess();
  const disabled = cannotWrite("curingest");
  return (
    <>
      <p>
        Once you&rsquo;ve followed the setup instructions, you can verify your
        access.
      </p>
      <FormButtons
        left
        noMargin
        disabled={disabled}
        infoMessage={
          disabled && "Connection can only be verified by administrators."
        }
        submitProps={{ style: { width: 150 } }}
        successMessage={verifyResponse?.status === "valid" && "Looking good!"}
        errorMessage={
          verifyResponse?.status === "invalid" && (
            <span>
              Something went wrong. Please check your setup and try again.
              <br />
              {verifyResponse.verificationFailures.join(", ")}
            </span>
          )
        }
        onSubmit={onVerify}
      >
        Verify Connection
      </FormButtons>
    </>
  );
}
